import "./download.scss";
import React from "react";
import { useEffect, useState } from "react";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
// api
import { downloadAndOpenPdf } from "../../api/auth";
// devextreme
import { Button } from "devextreme-react/button";

export default function Download() {
  const { user } = useAuth();

  const [isDeveloperDownloadButtonHidden, setIsDeveloperDownloadButtonHidden] = useState(true);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    let acc_admin = 0;
    if (user) {
      acc_admin = user.acc_admin; // (admin) [1] Device Cal & CoA W
    }
    if (U.bitAt(acc_admin, 1)) {
      setIsDeveloperDownloadButtonHidden(false);
    } else {
      setIsDeveloperDownloadButtonHidden(true);
    }
  }, []);

  function onDownload1ButtonClicked() {
    let fileName = "1.pdf"; // Embedded SW Download Manual
    downloadAndOpenPdf("/coa/downloaddocument", fileName);
  }

  function onDownload2ButtonClicked() {
    let fileName = "2.pdf"; // CellShot User Manual
    downloadAndOpenPdf("/coa/downloaddocument", fileName);
  }

  function onDownload3ButtonClicked() {
    let fileName = "3.pdf"; // CellShot Q/C SoP
    downloadAndOpenPdf("/coa/downloaddocument", fileName);
  }

  function onDownload4ButtonClicked() {
    let fileName = "4.pdf"; // CellShot CoA SoP
    downloadAndOpenPdf("/coa/downloaddocument", fileName);
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Download</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerHDownload"}>
            <div className={"flex-item1"}>
              <Button text="Download" onClick={onDownload2ButtonClicked} type="success" icon="download" />
            </div>
            <div className={"flex-item1"}>
              <p className={"text-title"}>CellShot User Manual (2025.01.18)</p>
            </div>
          </div>

          <div className={"flex-containerHDownload"}>
            <div className={"flex-item1"} hidden={isDeveloperDownloadButtonHidden}>
              <Button text="Download" onClick={onDownload1ButtonClicked} type="success" icon="download" />
            </div>
            <div className={"flex-item1"} hidden={isDeveloperDownloadButtonHidden}>
              <p className={"text-title"}>CellShot Embedded SW and Mainboard Firmware Download Manual (ver.1.0)</p>
            </div>
          </div>

          <div className={"flex-containerHDownload"}>
            <div className={"flex-item1"} hidden={isDeveloperDownloadButtonHidden}>
              <Button text="Download" onClick={onDownload3ButtonClicked} type="success" icon="download" />
            </div>
            <div className={"flex-item1"} hidden={isDeveloperDownloadButtonHidden}>
              <p className={"text-title"}>CellShot QC SOP (ver.1.0)</p>
            </div>
          </div>

          <div className={"flex-containerHDownload"}>
            <div className={"flex-item1"} hidden={isDeveloperDownloadButtonHidden}>
              <Button text="Download" onClick={onDownload4ButtonClicked} type="success" icon="download" />
            </div>
            <div className={"flex-item1"} hidden={isDeveloperDownloadButtonHidden}>
              <p className={"text-title"}>CellShot CoA SOP (ver.1.0)</p>
            </div>
          </div>
        </div>
        <div className="widget-container"></div>
      </div>
    </React.Fragment>
  );
}
